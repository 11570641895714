import * as React from 'react'

import { graphql } from 'gatsby'

import { BannerPage } from '../../components/BannerPage'
import { Container } from '../../components/Container'
import { ContinentsList } from '../../components/ContinentsList'
import { EpisodeList } from '../../components/EpisodeList'
import { LinkCustom } from '../../components/LinkCustom'
import { Platforms } from '../../components/Platforms'
import { Seo } from '../../components/Seo'

const Episodes: React.FC = ({ data, pageContext }) => {
  const { continents, episodes } = data
  const { name, link, description } = continents.nodes[0]

  return (
    <>
      <Seo
        title={`${name} Episodes`}
        description={description}
        pathname={pageContext.slug}
        isSubLanding
      />

      <BannerPage
        title={`${name} Episodes`}
        description={description}
        component={
          <div className="uppercase">
            Also listen on <Platforms />
          </div>
        }
      />

      <ContinentsList active={link} opacity={true} />

      <Container className="pt-10">
        <h2 className="text-2xl font-medium leading-8 mb-7 sm:leading-10 sm:text-4xl text-gray-900">
          Latests Episodes from {name}
        </h2>
        {episodes.edges.length ? (
          <EpisodeList podcasts={episodes} />
        ) : (
          <div className="text-center py-20">
            <p className="text-2xl pb-4">Sorry! There are no episodes yet.</p>
            <p className="text-gray-600">
              Maybe you want to be our next <LinkCustom to="/be-a-guest">be a guest</LinkCustom>.
            </p>
          </div>
        )}
      </Container>
    </>
  )
}

export default Episodes

export const query = graphql`
  query AllEpisodesQuery($slug: String, $continent: String) {
    episodes: allMdx(
      filter: { frontmatter: { active: { eq: true } }, fileAbsolutePath: { regex: $slug } }
      sort: { fields: frontmatter___publicationDate, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            duration
            url
            episodeNumber
            publicationDate
            imageCover
            country
          }
        }
      }
    }
    continents: allContinentsYaml(filter: { name: { eq: $continent } }) {
      nodes {
        name
        link
        description
      }
    }
  }
`
